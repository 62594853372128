import {
  fbqCustomEvent,
  gtagEvent,
  mixpanel,
  parselyTrackLeadCapture,
} from '../../../lib/analytics/appAnalytics'
import {baseUrl} from '../../../lib/constants'
import {
  MIXPANEL_EVENT_VIEWED_SIGN_UP_MODAL,
  MIXPANEL_JOURNEY_SUBSCRIBE_MODAL,
  MIXPANEL_PLATFORM,
} from '../mixpanel'
import {getDocumentTitle} from '../utils'

export default function trackViewedSubscribeModal() {
  // This function makes API calls to third party platforms for conversion tracking and analytics purposes.
  const eventName = 'Viewed Glance Subscribe Modal'
  const page = `${baseUrl}${window.location.pathname}`

  mixpanel.track(MIXPANEL_EVENT_VIEWED_SIGN_UP_MODAL, {
    Page: page,
    Journey: MIXPANEL_JOURNEY_SUBSCRIBE_MODAL,
    Platform: MIXPANEL_PLATFORM,
    Title: getDocumentTitle(),
  })
  gtagEvent({
    action: eventName,
    category: 'glance-subscription',
    label: page,
    value: undefined,
  })
  fbqCustomEvent(eventName, {
    page,
  })
  parselyTrackLeadCapture(eventName)
}
