import mp from 'mixpanel-browser'

export const ANALYTICS_ENABLED = process.env.NODE_ENV === 'production'

export const ADROLL_ADV_ID = process.env.NEXT_PUBLIC_ADROLL_ADV_ID
export const ADROLL_PIX_ID = process.env.NEXT_PUBLIC_ADROLL_PIX_ID
export const FB_CC_PIXEL_ID = process.env.NEXT_PUBLIC_FB_CC_PIXEL_ID
export const FB_OPHTHALMOLOGY_PIXEL_ID =
  process.env.NEXT_PUBLIC_FB_OPHTHALMOLOGY_PIXEL_ID
export const G_AW_ID = process.env.NEXT_PUBLIC_GOOGLE_ADWORDS_ID
export const G_GA4_ID = process.env.NEXT_PUBLIC_GOOGLE_GA4_ID
export const G_UA_ID = process.env.NEXT_PUBLIC_GOOGLE_UA_ID
export const HUBSPOT_ACCOUNT_ID = process.env.NEXT_PUBLIC_HUBSPOT_ACCOUNT_ID
export const LINKEDIN_PARTNER_ID = process.env.NEXT_PUBLIC_LI_INSIGHT_TAG_ID
export const MIXPANEL_PROJECT_TOKEN =
  process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN
export const TWITTER_OPTOMETRY_ID =
  process.env.NEXT_PUBLIC_TWITTER_OPTOMETRY_PIXEL_ID
export const TWITTER_OPHTHALMOLOGY_ID =
  process.env.NEXT_PUBLIC_TWITTER_OPHTHALMOLOGY_PIXEL_ID
export const PARSELY_SITE_ID = process.env.NEXT_PUBLIC_PARSELY_SITE_ID

// export const adrollEvent = (segment) => {
//   if (ANALYTICS_ENABLED) {
//     try {
//       window.__adroll.record_user({adroll_segments: segment})
//     } catch (e) {
//       // continue regardless of error
//     }
//   }
// }

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gtagPageview = (url: string) => {
  if (ANALYTICS_ENABLED) {
    try {
      gtag('event', 'page_view', {
        page_path: url,
      })
    } catch (e) {
      // continue regardless of error
    }
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
interface gtagProps {
  action: string
  category: string
  label: string
  value?: string
}

interface GoogleProps {
  event_category?: string
  event_label?: string
  page_location?: string
  page_title?: string
  send_to?: string
  value?: string
}

interface CustomDimensions {
  dimension18?: string
  dimension19?: string | null
  dimension20?: string[]
}

export const gtagEvent = ({action, category, label, value}: gtagProps) => {
  if (ANALYTICS_ENABLED) {
    try {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
      })
    } catch (e) {
      // continue regardless of error
    }
  }
}

interface gtagCustomDestinationProps extends gtagProps {
  sendTo: string
}

export const gtagEventCustomDestination = ({
  action,
  category,
  label,
  value,
  sendTo,
}: gtagCustomDestinationProps) => {
  if (ANALYTICS_ENABLED) {
    try {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
        send_to: sendTo,
      })
    } catch (e) {
      // continue regardless of error
    }
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const trackGoogleEvent = (
  action: string,
  props: GoogleProps,
  customDimensions?: CustomDimensions,
) => {
  if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
  try {
    window.gtag('event', action, {
      send_page_view: false,
      ...props,
      ...customDimensions,
    })
  } catch (e) {
    // continue regardless of error
  }
}

export const mixpanel = {
  identify: (id: string) => {
    if (ANALYTICS_ENABLED) {
      try {
        mp.identify(id)
      } catch (e) {
        // continue regardless of error
      }
    }
  },
  get_distinct_id: () => {
    if (ANALYTICS_ENABLED) {
      try {
        return mp.get_distinct_id()
      } catch (e) {
        // continue regardless of error
      }
    }
  },
  alias: (newId: string, existingId: string) => {
    if (ANALYTICS_ENABLED) {
      try {
        mp.alias(newId, existingId)
      } catch (e) {
        // continue regardless of error
      }
    }
  },
  register: (props: Mixpanel.EventBaseType['properties']) => {
    if (ANALYTICS_ENABLED) {
      try {
        mp.register(props)
      } catch (e) {
        // continue regardless of error
      }
    }
  },
  track: (name: string, props: Mixpanel.EventBaseType['properties']) => {
    if (ANALYTICS_ENABLED) {
      try {
        mp.track(name, props)
      } catch (e) {
        // continue regardless of error
      }
    }
  },
  people: {
    set: (props: Mixpanel.EventBaseType['properties']) => {
      if (ANALYTICS_ENABLED) {
        try {
          mp.people.set(props)
        } catch (e) {
          // continue regardless of error
        }
      }
    },
    set_once: (props: Mixpanel.EventBaseType['properties']) => {
      if (ANALYTICS_ENABLED) {
        try {
          mp.people.set_once(props)
        } catch (e) {
          // continue regardless of error
        }
      }
    },
  },
  reset: () => {
    if (ANALYTICS_ENABLED) {
      try {
        mp.reset()
      } catch (e) {
        // continue regardless of error
      }
    }
  },
}

export const parselyTrackLeadCapture = (label: string) => {
  const enabled = ['']
  // We're only paying fot a single parsely conversion right now
  if (!enabled.includes(label)) return
  if (ANALYTICS_ENABLED) {
    try {
      window.PARSELY.conversions.trackLeadCapture(label)
    } catch (e) {
      // continue regardless of error
    }
  }
}

export const fbqPageview = () => {
  if (ANALYTICS_ENABLED) {
    try {
      window.fbq('track', 'PageView')
    } catch (e) {
      // continue regardless of error
    }
  }
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const fbqEvent = (name: string, options?: {[key: string]: string}) => {
  if (ANALYTICS_ENABLED) {
    try {
      if (options) {
        window.fbq('track', name, options)
      } else {
        window.fbq('track', name)
      }
    } catch (e) {
      // continue regardless of error
    }
  }
}
export const fbqCustomEvent = (
  name: string,
  options?: {[key: string]: string},
) => {
  if (ANALYTICS_ENABLED) {
    try {
      if (options) {
        window.fbq('trackCustom', name, options)
      } else {
        window.fbq('trackCustom', name)
      }
    } catch (e) {
      // continue regardless of error
    }
  }
}

export const lintrkEvent = (id: number) => {
  // for LinkedIn Insight Tag event-specific conversion tracking
  if (ANALYTICS_ENABLED) {
    try {
      window.lintrk('track', {conversion_id: id})
    } catch (e) {
      // continue regardless of error
    }
  }
}

// export const twqEvent = (id: string) => {
//   if (ANALYTICS_ENABLED) {
//     try {
//       window.twq('event', id, {})
//     } catch (e) {
//       // continue regardless of error
//     }
//   }
// }
