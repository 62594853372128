import {useContext, useEffect} from 'react'
import {useForm} from 'react-hook-form'

import * as Sentry from '@sentry/nextjs'

import {AuthenticationContext} from '../../lib/auth'
import {
  HS_ROLE_CONSUMER,
  HS_ROLE_OPHTHALMOLOGIST,
  HS_ROLE_OPTOMETRIST,
} from '../../lib/constants'
import {useUrl} from '../../lib/hooks/useUrl'
import {trpc} from '../../utils/trpc'
import trackCompletedSignUp from '../appAnalytics/events/trackCompletedSignUp'
import trackStartedSignUp from '../appAnalytics/events/trackStartedSignUp'
import BooleanSelectInput from './BooleanSelectInput'
import CountriesSelectInput from './CountriesSelectInput'
import FormError from './FormError'
import GraduationYearInput from './GraduationYearInput'
import RolesSelectInput from './RolesSelectInput'
import SpecialtiesSelectInput from './SpecialtiesSelectInput'
import {MIXPANEL_PLATFORM} from '../appAnalytics/mixpanel'
import {mixpanel} from '../../lib/analytics/appAnalytics'
import {getDocumentTitle, getUTMsFromCookie} from '../appAnalytics/utils'

type SignupFormProps = {
  onSuccess?: () => void
  source: string
}

export default function SignUpForm({onSuccess, source}: SignupFormProps) {
  const {url} = useUrl()
  const submitSignUp = trpc.auth.signUp.useMutation({
    onSuccess: () => {
      onSuccess?.()
      trackCompletedSignUp(url || 'glance-subscription', source)
    },
    onError: (e) => {
      Sentry.captureException(e)
    },
  })
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: {errors, isDirty},
  } = useForm({
    defaultValues: {
      // all default inputs are needed for isDirty to work:
      // https://react-hook-form.com/docs/useform/formstate
      country: {label: 'United States of America', value: 'US'},
      email: '',
      full_name: '',
      is_practice_owner: false,
      role: {value: ''},
    },
  })
  const roleSelection = watch('role')

  // TODO: add types for react-hook-form library
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    const mixpanelDistinctId = mixpanel.get_distinct_id()
    const utms = getUTMsFromCookie()

    const cleanedData = {
      country: data.country.value,
      email: data.email,
      full_name: data.full_name,
      graduation_year: data.graduation_year
        ? Number(data.graduation_year)
        : undefined,
      is_practice_owner: data.is_practice_owner?.value || false,
      role: data.role.value,
      specialties:
        data.specialties?.map(
          (specialty: {label: string; value: string}) => specialty.value,
        ) || [],
      page_url: window.location.href,
      page_name: document.title,
      gated_content_slug: 'glance-subscription',
      redirect_to: url ?? undefined,
    }
    submitSignUp.mutate({
      signUpData: cleanedData,
      mixpanelData: {
        mixpanel_distinct_id: mixpanelDistinctId,
        Journey: source,
        Platform: MIXPANEL_PLATFORM,
        Title: getDocumentTitle(),
        utms,
      },
    })
  }

  const {user} = useContext(AuthenticationContext)

  useEffect(() => {
    if (isDirty) {
      trackStartedSignUp(url, source)
    }
  }, [isDirty, source, url])

  return (
    <>
      <h3 id="subscribe" className="text-center">
        Subscribe to Glance Now!
      </h3>
      <p className="text-muted text-center">
        Get your Monday morning email rundown of recent optometry and
        ophthalmology headlines, curated by Dr. Jackie Garlich.
      </p>
      {submitSignUp.isSuccess && (
        <div className="text-center alert alert-success mt-3">
          {submitSignUp.data.message}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column">
          {/* Name */}
          <div className="mb-3">
            <label htmlFor="fullName" className="form-label mb-0">
              Full Name<span className="text-danger">*</span>
            </label>
            <FormError name="full_name" errors={errors} />
            <input
              id="fullName"
              className="form-control"
              {...register('full_name', {
                required: 'Full name is required.',
              })}
            />
          </div>

          {/* Email */}
          <div className="mb-3">
            <label htmlFor="email" className="form-label mb-0">
              Email<span className="text-danger">*</span>
            </label>
            <FormError name="email" errors={errors} />
            <input
              id="email"
              className="form-control"
              {...register('email', {
                required: 'Email address is required.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter a valid email address.',
                },
              })}
            />
          </div>

          {/* Country */}
          <div className="mb-3">
            <label htmlFor="country" className="form-label mb-0">
              Country<span className="text-danger">*</span>
            </label>
            <FormError name="country" errors={errors} />
            <CountriesSelectInput inputName="country" control={control} />
          </div>

          {/* Role */}
          <div className="mb-3">
            <label htmlFor="roles" className="form-label mb-0">
              What is your job title or function? If you are a student, select
              the role you are studying for.
              <span className="text-danger">*</span>
            </label>
            <FormError name="role" errors={errors} />
            <RolesSelectInput inputName="role" control={control} />
          </div>

          {/* Graduation year */}
          {(roleSelection?.value === HS_ROLE_OPTOMETRIST ||
            roleSelection?.value === HS_ROLE_OPHTHALMOLOGIST) && (
            <div className="mb-3">
              <GraduationYearInput
                control={control}
                // eslint-disable-next-line jsx-a11y/aria-role
                role={roleSelection?.value}
                inputName="graduation_year"
                required={
                  roleSelection?.value === HS_ROLE_OPTOMETRIST ||
                  roleSelection?.value === HS_ROLE_OPHTHALMOLOGIST
                }
                errors={errors}
              />
            </div>
          )}

          {/* Specialties */}
          {(roleSelection?.value === HS_ROLE_OPTOMETRIST ||
            roleSelection?.value === HS_ROLE_OPHTHALMOLOGIST) && (
            <div className="mb-3">
              <SpecialtiesSelectInput
                // eslint-disable-next-line jsx-a11y/aria-role
                role={roleSelection?.value}
                control={control}
                inputName="specialties"
                required={
                  roleSelection?.value === HS_ROLE_OPTOMETRIST ||
                  roleSelection?.value === HS_ROLE_OPHTHALMOLOGIST
                }
                errors={errors}
              />
            </div>
          )}

          {/* Practice Owner? */}
          {roleSelection?.value !== HS_ROLE_CONSUMER && (
            <div className="mb-3">
              <label htmlFor="is_practice_owner" className="form-label mb-0">
                Are you a practice owner?
              </label>{' '}
              <FormError name="is_practice_owner" errors={errors} />
              <BooleanSelectInput
                control={control}
                inputName="is_practice_owner"
              />
            </div>
          )}
          {/* Submit */}
          <div className="d-flex flex-column justify-content-center mb-4">
            <button
              className="btn btn-lg btn-primary"
              type="submit"
              disabled={
                submitSignUp.isLoading || !!user || submitSignUp.isSuccess
              }
            >
              {submitSignUp.isLoading
                ? 'Subscribing...'
                : submitSignUp.isSuccess
                  ? 'Subscribed!'
                  : 'Subscribe'}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
