import {z} from 'zod'

import {createContext, useState} from 'react'
import {CloseButton, Modal} from 'react-bootstrap'

import trackViewedSubscribeModal from '../../components/appAnalytics/events/trackViewedSubscribeModal'
import {ImpersonationNav} from '../../components/auth/ImpersonationNav'
import {SubscribeComponent} from '../../components/forms/SubscribeComponent'
import {trpc} from '../../utils/trpc'
import * as requests from './requests'
import * as types from './types'
import {useLocalStorage} from 'usehooks-ts'
import {
  identifyMixpanelUser,
  MIXPANEL_JOURNEY_SUBSCRIBE_MODAL,
} from '../../components/appAnalytics/mixpanel'
import {mixpanel} from '../analytics/appAnalytics'

/**
 * Export Auth types from this index file to simplify imports downstream.
 */
export {types, requests}

const AuthenticationContextSchema = z.object({
  user: types.UserSchema.nullable(),
  isAuthenticating: z.boolean(),
  signOut: z.function(),
  openAuthModal: z.function(),
})
export const AuthenticationContext = createContext<
  z.infer<typeof AuthenticationContextSchema>
>({
  user: null,
  isAuthenticating: false,
  signOut: () => void 0,
  openAuthModal: () => void 0,
})
export const AuthenticationProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [hasSignedOut, setHasSignedOut] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [, setCookiedUUID] = useLocalStorage<string | null>('eoeUUID', null)

  const [user, setUser] = useState<types.User | null>(null)
  const {isLoading} = trpc.auth.getUser.useQuery(undefined, {
    onSettled: (user) => {
      setUser(user ?? null)
      // Set a cross-platform cookie with the user's ID for tracking
      if (user?.profile?.uuid) {
        setCookiedUUID(user.profile.uuid)
      }
      if (user) identifyMixpanelUser(user)
    },
    refetchOnWindowFocus: false,
    enabled: !(user || hasSignedOut),
  })

  const trySignOut = trpc.auth.signOut.useMutation({
    onMutate: () => {
      setHasSignedOut(true) // don't refetch user on change
      setUser(null) // immediately remove user from context, no need to wait for a response
    },
    onSuccess: () => {
      mixpanel.reset()
    },
  })
  const signOut = () => trySignOut.mutate()
  const openAuthModal = () => {
    setIsOpen(true)
    trackViewedSubscribeModal()
  }

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        isAuthenticating: isLoading,
        signOut,
        openAuthModal,
      }}
    >
      {user?.impersonation_release_url && (
        <ImpersonationNav
          email={user.email}
          releaseUrl={user.impersonation_release_url}
        />
      )}
      {children}
      <Modal show={isOpen} onHide={() => setIsOpen(false)} centered size="lg">
        <Modal.Body className="p-4 p-lg-5">
          <CloseButton
            className={'float-end'}
            onClick={() => setIsOpen(false)}
          />
          <SubscribeComponent source={MIXPANEL_JOURNEY_SUBSCRIBE_MODAL} />
        </Modal.Body>
      </Modal>
    </AuthenticationContext.Provider>
  )
}
