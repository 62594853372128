import {z} from 'zod'
import {MixpanelDataInput} from '../../components/appAnalytics/mixpanel'

/**
 * Send a request to the Django server to ask who is logged in.
 * Django looks at the session cookie on the incoming request and returns the result.
 */
export const getMe = async (sessionId: string | null) => {
  return await fetch(`${process.env.BACKEND_API_URL}/auth/me/?platform=eoe`, {
    headers: {
      Cookie: `${sessionId ? `eoesessionid=${sessionId}` : ''}`,
    },
  })
}

/**
 * Send a request to the Django server to sign up a user.
 * Sign-ups require the EyesOnEyecare gated content fields.
 * @param input
 */
export const signUp = async (
  signUpData: z.infer<typeof SignUpInputSchema>,
  mixpanelData: MixpanelDataInput,
  rawCookies: string,
  ipAddress: string | string[] | undefined,
) => {
  if (ipAddress && Array.isArray(ipAddress)) {
    ipAddress = ipAddress[0]
  }
  return await fetch(
    `${process.env.BACKEND_API_URL}/resources/gated-content-submission/`,
    {
      method: 'post',
      headers: {
        // Forward the IP address and cookies of the user to Django so they
        // can be used for Hubspot form submissions.
        'X-Forwarded-For': ipAddress ?? '',
        Cookie: rawCookies ?? '',
        accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        ...signUpData,
        mixpanel_data: mixpanelData,
      }),
    },
  )
}
export const SignUpInputSchema = z.object({
  full_name: z.string(),
  email: z.string().email(),
  graduation_year: z.number().int().min(1900).max(2050).optional(),
  is_practice_owner: z.boolean().optional(),
  role: z.string(),
  specialties: z.array(z.string()).optional(),
  country: z.string(),
  gated_content_slug: z.string(),
  page_name: z.string(),
  page_url: z.string().optional(),
  redirect_to: z.string().optional(),
})

/**
 * Send a request to the Django server to sign out the current user.
 * The server always responds with a 200 status code even if the user is not signed in.
 */
export async function signOut(sessionId: string | null): Promise<Response> {
  return await fetch(`${process.env.BACKEND_API_URL}/auth/sign-out/`, {
    method: 'post',
    credentials: 'include',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      Cookie: `${sessionId ? `eoesessionid=${sessionId}` : ''}`,
    },
  })
}

/**
 * Ask the Django server to send a magic link to a given email address.
 * The server responds with {success: true} if the email was sent.
 * The server responds with {success: false} if something went wrong (e.g. the users doesn't exist, the email address is invalid).
 */
export const signIn = async (
  input: z.infer<typeof SignInInputSchema>,
): Promise<Response> => {
  return await fetch(`${process.env.BACKEND_API_URL}/auth/sign-in/`, {
    method: 'post',
    credentials: 'include',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify(input),
  })
}
export const SignInInputSchema = z.object({
  email: z.string().email(),
  next: z.string().optional(),
})
export const SignInOutputSchema = z.object({
  status: z.number(),
  message: z.string(),
})

export const resubscribe = async (rawCookies?: string, csrfToken?: string) => {
  return await fetch(
    `${process.env.BACKEND_API_URL}/subscriptions/request-preferences-link/`,
    {
      method: 'post',
      headers: {
        'x-csrftoken': csrfToken || '',
        accept: 'application/json',
        'content-type': 'application/json',
        Cookie: rawCookies || '',
      },
      body: JSON.stringify({}),
    },
  )
}

export const isOptedOutOfEmails = async (
  rawCookies?: string,
  csrfToken?: string,
) => {
  return await fetch(
    `${process.env.BACKEND_API_URL}/subscriptions/is-opted-out/`,
    {
      headers: {
        'x-csrftoken': csrfToken || '',
        accept: 'application/json',
        'content-type': 'application/json',
        Cookie: rawCookies || '',
      },
    },
  )
}
