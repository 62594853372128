// src/pages/_app.tsx
import 'bootstrap/dist/css/bootstrap.min.css'

import {useEffect} from 'react'
import SSRProvider from 'react-bootstrap/SSRProvider'

import type {AppType} from 'next/app'
import {useRouter} from 'next/router'

import {config} from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'

import AppAnalyticsInits from '../components/appAnalytics/AppAnalyticsInits'
import ParselyScript from '../components/appAnalytics/ParselyInit'
import trackPageview from '../components/appAnalytics/pageview/trackPageview'
import DefaultLayout from '../components/layouts/DefaultLayout'
import {GPTProvider} from '../lib/ads/GPTContext'
import GPTScripts from '../lib/ads/GPTScripts'
import {AuthenticationProvider} from '../lib/auth'
import '../styles/custom.scss'
import '../styles/globals.scss'
import {trpc} from '../utils/trpc'
import {saveUTMsToCookie} from '../components/appAnalytics/utils'

// https://fontawesome.com/docs/web/use-with/react/use-with#next-js
config.autoAddCss = false

const MyApp: AppType = ({Component, pageProps}) => {
  useEffect(() => {
    // TODO: FIGURE OUT BOOTSTRAP TYPING: https://app.clickup.com/t/3t23a6a
    // @ts-expect-error no typing for bootstrap's js files
    import('bootstrap/dist/js/bootstrap')
  }, [])

  // Track route changes and send page views to Google Tag Manager
  const router = useRouter()
  useEffect(() => {
    router.events.on('routeChangeComplete', trackPageview)
    return () => {
      router.events.off('routeChangeComplete', trackPageview)
    }
  }, [router.events])

  // Ensure that utms persist throughout a session and are
  // updated on route changes in case the user navigates to a page with other utms
  useEffect(() => {
    saveUTMsToCookie()
  }, [router])

  const getLayout =
    // @ts-expect-error we are going to use next's layout features
    Component.getLayout || ((page) => <DefaultLayout>{page}</DefaultLayout>)

  return (
    <>
      <AuthenticationProvider>
        <AppAnalyticsInits />
        <GPTProvider>
          <GPTScripts />
          {getLayout(
            <SSRProvider>
              <Component {...pageProps} />
            </SSRProvider>,
            pageProps,
          )}
        </GPTProvider>
        <ParselyScript />
      </AuthenticationProvider>
    </>
  )
}

export default trpc.withTRPC(MyApp)
