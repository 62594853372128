import {
  fbqCustomEvent,
  gtagEvent,
  mixpanel,
  parselyTrackLeadCapture,
} from '../../../lib/analytics/appAnalytics'
import {baseUrl} from '../../../lib/constants'
import {
  MIXPANEL_EVENT_STARTED_SIGN_UP_FORM,
  MIXPANEL_PLATFORM,
} from '../mixpanel'
import {getDocumentTitle} from '../utils'

export default function trackStartedSignUp(url: string | null, source: string) {
  // This function makes API calls to third party platforms for conversion tracking and analytics purposes.
  const eventName = 'Started Glance Subscribe Form'
  const page = url || baseUrl

  mixpanel.track(MIXPANEL_EVENT_STARTED_SIGN_UP_FORM, {
    Journey: source,
    Platform: MIXPANEL_PLATFORM,
    Title: getDocumentTitle(),
  })
  gtagEvent({
    action: eventName,
    category: 'glance-subscription',
    label: page,
    value: undefined,
  })
  fbqCustomEvent(eventName, {
    page,
  })
  parselyTrackLeadCapture(eventName)
}
