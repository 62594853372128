import {ANALYTICS_ENABLED} from '../../lib/analytics/appAnalytics'
import AdRollInit from './AdRollInit'
import FacebookPixelInit from './FacebookPixelInit'
import {
  GoogleAnalyticsInit,
  GoogleAnalyticsUserInit,
} from './GoogleAnalyticsInit'
import HubspotInit from './HubspotInit'
import LinkedInInit from './LinkedInInit'
import {initializeMixpanel} from './mixpanel'

export default function AppAnalyticsInits() {
  if (ANALYTICS_ENABLED) {
    initializeMixpanel()
  }

  return ANALYTICS_ENABLED ? (
    <>
      <GoogleAnalyticsInit />
      <GoogleAnalyticsUserInit />
      <FacebookPixelInit />
      <AdRollInit />
      <LinkedInInit />
      <HubspotInit />
    </>
  ) : null
}
